import {EduNavbar} from "./EduNavbar";

export const Undergraduate = () => {
    return (
        <div className="education">
            <EduNavbar/>
            <br/>
            <br/>
            <br/>
            <br/>
            <h1 style={{background:"black",margin:"50px",padding:"20px"}}>Bachelor of Engineering in Electronics and Communication Engineering from Sri Sivasubramaniya college of Engineering - Chennai.</h1>


            <h3 style={{marginBottom:"10px",marginLeft:"100px",marginRight:"100px",background:"black"}}>GPA - 8.54 / 10</h3>
            <br/>



        </div>
    )
}